<template>
  <div
    class="container main-content"
    v-loading="loading"
    element-loading-text="正在保存，请稍等。。。"
    element-loading-spinner="el-icon-loading"
  >
    <div class="crt-content">
      <div class="crt-content-left">
        <el-button
          v-if="isResearch"
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-edit"
          @click="changeBasicInfo"
        >
          编辑
        </el-button>
        <div v-else>
          <el-button
            type="primary"
            class="common-button commonBtn"
            icon="el-icon-edit"
            @click="changeBasicInfo"
          >
            取消
          </el-button>
          <el-button
            v-if="!isResearch"
            :disabled="disabled"
            :type="type"
            size="mini"
            icon="el-icon-edit"
            class="commonBtn"
            @click="saveBasicInfo"
          >
            保存
          </el-button>
        </div>
      </div>
      <div class="crt-content-right">
        <el-button size="mini" icon="el-icon-plus" @click="handleLeading('auto')">
          自动引入
        </el-button>
        <el-button size="mini" icon="el-icon-plus" @click="handleLeading">导入LIS</el-button>
        <el-button size="mini" icon="el-icon-plus" @click="handlePACS">导入检查报告</el-button>
        <el-button size="mini" icon="el-icon-plus" @click="handleUpPACS">上传本地PDF</el-button>
        <el-upload
          ref="upload"
          style="display: none"
          action=""
          :show-file-list="false"
          :http-request="pdfImport"
          multiple
        >
          <el-button size="mini" type="primary">上传本地PDF</el-button>
        </el-upload>
      </div>
    </div>
    <el-card class="body">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in tableList"
          :key="item.id"
          :label="getLabel(item)"
          :name="String(index)"
        >
          <readonly-tab
            :isReadonly="isReadonly"
            :ref="'tab' + index"
            :info="info"
            :tabInfo="{ ...item, ...tabDataList[Number(index)] }"
            :opStage="menuListData.opStage"
            :data="tabDataList[Number(index)]?.tab"
            :dataUuid="handleItemUuid"
            :examinationInfo="tabDataList[Number(index)]?.examinationInfo"
            @closeLoading="isLoading"
            @save="saveEFormInfo"
            @handleDownload="handleDownloadPdf"
            @delExamine="delExamine"
          />
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
      width="30%"
      title="检查类型选择"
      class="add-pacs"
      :visible.sync="pacsTypeInnerVisible"
    >
      <div class="">
        <span>请选择检查类型：</span>
        <el-select v-model="PACSTypeCode" placeholder="请选择" @change="handlePACSTemplet">
          <el-option
            v-for="item in PACSOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </el-dialog>
    <el-dialog
      width="30%"
      title="表单引入"
      class="add-form auto-leading"
      :visible.sync="innerVisibleAutoLeading"
    >
      <div class="dialog-btn-wrap">
        <template>
          <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
            查询前先同步LIS
          </el-checkbox>
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            @click="autoLeading"
            icon="el-icon-search"
          >
            确认
          </el-button>
        </template>
      </div>
    </el-dialog>

    <el-dialog width="60%" title="表单引入" class="add-form" :visible.sync="innerVisible">
      <div class="dialog-btn-wrap">
        <template>
          按日期查询：
          <el-date-picker
            v-model="dialogQueryDate"
            type="daterange"
            align="center"
            size="mini"
            style="margin-right: 10px"
            unlink-panels
            @change="handleDialogQueryDate"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
          <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
            查询前先同步LIS
          </el-checkbox>
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            @click="fetchExamineData"
            icon="el-icon-search"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="saveOrAdviceList"
            icon="el-icon-s-promotion"
            class="commonBtn"
          >
            引入
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="allSaveOrAdviceList"
            icon="el-icon-s-promotion"
            class="commonBtn"
          >
            全部引入
          </el-button>
        </template>
      </div>
      <div class="form-wrap" v-if="queryExamOrAdviceList.length > 0">
        <div class="adviceTable">
          <div class="adviceTableLabel">
            <div class="adviceTableHeader"></div>
            <div
              class="adviceTableLabelItem"
              v-for="(ele, index) in columnHeaderList"
              :key="'title' + index"
            >
              {{ ele.itemTitle }}{{ ele.itemUnit }}
            </div>
          </div>
          <div class="adviceTablePreviewLabel">
            <div class="adviceTableHeader">预览</div>
            <div
              class="adviceTableLabelItem"
              v-for="(ele, index) in columnPreviewList"
              :key="'title' + index"
            >
              {{ ele.itemValue }}
            </div>
          </div>
          <div class="adviceTableBox">
            <div
              class="adviceTableContent"
              v-for="(column, columnIndex) in queryExamOrAdviceList"
              :key="columnIndex"
            >
              <div
                @click="selectColumns(columnIndex)"
                class="adviceTableHeader"
                :class="column.defaultHighlight ? 'queryExamToday' : ''"
              >
                {{ column.defaultHighlight ? '✔️' : '' }}
                {{ column.examineDate.substr(0, 16) }}
              </div>
              <div
                class="adviceTableContentItem"
                v-for="(cell, cellIndex) in column?.items"
                :key="cellIndex + cell.uuid"
                :class="{ 'cell-pitch_on': cell._isSecect }"
                @click="handleItem(column, columnIndex, cell)"
              >
                {{ cell.itemValue }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-empty style="margin-top: 40px" v-else description="暂无数据" />
    </el-dialog>
    <el-dialog width="60%" title="检查报告导入" class="add-form" :visible.sync="PACSinnerVisible">
      <div class="dialog-btn-wrap">
        <template>
          按日期查询：
          <el-date-picker
            v-model="dialogQueryDate"
            type="daterange"
            align="center"
            size="mini"
            style="margin-right: 10px"
            unlink-panels
            @change="handleDialogQueryDate"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
          <el-checkbox v-model="syncFirstStatus" style="margin-right: 10px">
            查询前同步先检查报告
          </el-checkbox>
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            @click="fetchPACSExamineData"
            icon="el-icon-search"
          >
            查询
          </el-button>
        </template>
      </div>
      <div class="form-wrap" v-if="PACSExamineData.length > 0">
        <el-table
          :data="PACSExamineData"
          class="PACSExamineTable"
          border
          :header-cell-style="{ background: '#eef1f6', color: '#606266', fontSize: '15px' }"
          height="100%"
        >
          <el-table-column
            prop="examineType"
            align="center"
            fixed="left"
            label="检查报告"
            width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.examineType }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            align="center"
            label="检查结果"
            show-overflow-tooltip
            min-width="180"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.description }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="examineDate"
            align="center"
            label="检查时间"
            width="150"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="savePACSOrAdviceList(scope.row)"
                icon="el-icon-s-promotion"
              >
                引入
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-empty v-else style="margin-top: 40px" description="暂无数据" />
    </el-dialog>
  </div>
</template>

<script>
import ReadonlyTab from './components/ReadonlyTab.vue'
import { uniqueJsonArrByField, getBeforeDate, getLatestExamineData } from '../../../utils'
import { mapState } from 'vuex'
export default {
  name: 'AssessmentTemplate',
  components: {
    ReadonlyTab
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeName: '0',
      lastIndex: '0',
      tableList: [], // 表单列表
      tabDataList: [],
      isReadonly: true,
      isResearch: true,
      // 新增引入
      innerVisibleAutoLeading: false,
      innerVisible: false,
      PACSinnerVisible: false,
      pacsTypeInnerVisible: false,
      syncFirstStatus: true,
      PACSOptions: [],
      PACSTypeCode: '',
      queryExamOrAdviceList: [],
      columnHeaderList: [], // 列头
      columnPreviewList: [], // 预览
      dialogQueryDate: [getBeforeDate(7), getBeforeDate(0)],
      dialogQueryList: null,
      examineId: null,
      // 导入日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      handleItemUuid: [],
      checkDataId: '',
      disabled: false,
      type: 'primary',
      loading: false,
      PACSExamineData: [],
      scrollTrigger: false, // 默认初始值
      handleItemList: [],
      menuListData: null
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    },
    isResearch: {
      handler(val) {
        if (val) {
          this.isReadonly = true
        } else {
          this.isReadonly = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getPACSTypes()
  },

  methods: {
    getLabel(val) {
      if (val.alias) {
        return (
          val.alias + ' ' + (val.completion && val.completion !== 0 ? val.completion + '%' : '')
        )
      } else {
        return (
          val.formName + ' ' + (val.completion && val.completion !== 0 ? val.completion + '%' : '')
        )
      }
    },

    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.loading = true
            this.getTable()
          }
        })
      }
    },
    scrollToTop() {
      if (this.scrollTrigger) {
        return
      }
      // document.documentElement.scrollTop = 0 // Chrome, Firefox, IE and Opera
      // document.body.scrollTop = 0 // Safari
      const container = document.querySelector('.body') // 选择合适的父元素
      // if (container) {
      //   container.scrollTop = 0
      // }
      if (container.scrollTop > 0) {
        let scrollTop = container.scrollTop
        let steep = scrollTop / 2000
        let timer = setInterval(() => {
          this.scrollTrigger = true
          // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
          scrollTop -= steep
          // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
          steep += 20
          if (scrollTop <= 0) {
            clearInterval(timer)
            this.scrollTrigger = false
          }
          container.scrollTop = scrollTop
        }, 20)
      }
    },
    // 上传文件
    onUpload(formData) {
      this.$api
        .post(`/v1/webconsole/examination/import/operation/${this.info.uuid}`, formData)
        .then(({ data: res }) => {
          if (res && res.data) {
            this.isResearch = false
            let newArr = JSON.parse(JSON.stringify(this.tabDataList[Number(this.activeName)].tab))
            newArr.map((item) => {
              res.data.newForm.map((items) => {
                if (item.id === items.id) {
                  if (items.value) {
                    item.value = items.value
                  }
                }
              })
            })
            this.tabDataList[Number(this.activeName)].tab = newArr
          }
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.examineId = res.data.examine.uuid
          this.tabDataList[Number(this.activeName)].examinationInfo.push(res.data.examine)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    delExamine(val) {
      let formData = new FormData()
      formData.append('examineId', val)
      this.$api
        .post(
          `/v1/webconsole/eform/delete/formdata/examine/${this.tabDataList[this.activeName].id}`,
          formData
        )
        .then(() => {
          this.getExamination()
        })
    },
    handleDownloadPdf() {
      this.$api
        .get(`/v1/webconsole/examination/eform/examination/${this.tabDataList[this.activeName].id}`)
        .then(({ data: res }) => {
          if (res && res.data && res.data[0]?.sourcePath) {
            // this.axios
            //   .get(`/v1/webconsole/nurseHandover/preview?filename=${res.data[0].sourcePath}`, {
            //     responseType: 'blob'
            //   })
            //   .then((res) => {
            //     if (res.data && res.data.size !== 0) {
            //       let blob = new Blob([res.data], {
            //         //type类型后端返回来的数据中会有，根据自己实际进行修改
            //         type: 'application/pdf'
            //       })
            //       let filename = this.tableList[this.activeName].alias + '.pdf'
            //       if (typeof window.navigator.msSaveBlob !== 'undefined') {
            //         window.navigator.msSaveBlob(blob, filename)
            //       } else {
            //         var blobURL = window.URL.createObjectURL(blob)
            //         // 创建隐藏<a>标签进行下载
            //         var tempLink = document.createElement('a')
            //         tempLink.style.display = 'none'
            //         tempLink.href = blobURL
            //         tempLink.setAttribute('download', filename)
            //         if (typeof tempLink.download === 'undefined') {
            //           tempLink.setAttribute('target', '_blank')
            //         }
            //         document.body.appendChild(tempLink)
            //         tempLink.click()
            //         document.body.removeChild(tempLink)
            //         window.URL.revokeObjectURL(blobURL)
            //       }
            //     }
            //   })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getExamination() {
      this.$api
        .get(`/v1/webconsole/examination/eform/examination/${this.tabDataList[this.activeName].id}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.tabDataList[Number(this.activeName)].examinationInfo = res.data.data
          } else {
            this.tabDataList[Number(this.activeName)].examinationInfo = []
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 导入pdf
    pdfImport(file) {
      let formData = new FormData()
      formData.append('formId', this.tableList[this.activeName].formId)
      formData.append('businessCode', this.menuListData.opStage)
      formData.append('examTypeCode', this.PACSTypeCode)
      formData.append('file', file.file)
      this.onUpload(formData)
    },
    uniqueJsonArrByField,
    // 实验室数据引入
    autoLeading() {
      let formData = new FormData()
      if (this.tabDataList[this.activeName].id) {
        formData.append('formDataId', this.tabDataList[this.activeName].id)
      }
      if (this.syncFirstStatus) {
        formData.append('syncFirst', true)
      }
      this.$api
        .post(
          `/v1/webconsole/study/import/examination/operation/${this.info.uuid}/${
            this.tableList[this.activeName].formId
          }`,
          formData
        )
        .then(({ data: res }) => {
          if (res && res.data) {
            const elements = res.data.elements
            res.data.examinationIds.forEach((item) => {
              if (!this.handleItemUuid.includes(item)) {
                this.handleItemUuid.push(item)
              }
            })
            let newArr = JSON.parse(JSON.stringify(this.tabDataList[Number(this.activeName)].tab))
            newArr.map((item) => {
              item.elementId = item.id
              elements?.map((ele) => {
                if (item.id === ele.id) {
                  item.value = ele.value
                }
              })
            })
            this.tabDataList[Number(this.activeName)].isSave = false
            this.tabDataList[Number(this.activeName)].newTab = JSON.parse(
              JSON.stringify(this.tabDataList[Number(this.activeName)].tab)
            )
            setTimeout(() => {
              this.tabDataList[Number(this.activeName)].tab = newArr
            }, 200)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      this.isResearch = false
      this.isReadonly = false
      this.innerVisibleAutoLeading = false
    },
    handleLeading(type) {
      var date
      if (this.currentPatient.planStudyDate) {
        date = new Date(this.currentPatient.planStudyDate)
      } else {
        date = new Date()
      }
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const todayDate = `${year}-${month}-${day}`

      var timestamp = date.getTime() // 获取时间戳

      // 减去 7 天的毫秒数
      var subtractedTimestamp = timestamp - 7 * 24 * 60 * 60 * 1000

      var subtractedDate = new Date(subtractedTimestamp) // 创建新的 Date 对象
      var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      var formattedDate = subtractedDate.toLocaleDateString('zh-CN', options).replace(/\//g, '-')
      this.dialogQueryDate = [
        this.currentPatient?.admissionDate || formattedDate,
        this.currentPatient.planStudyDate || todayDate
      ]
      this.dialogQueryList = this.dialogQueryDate.join(',')
      this.syncFirstStatus = true
      this.queryExamOrAdviceList = []
      this.handleItemUuid = []
      this.handleItemList = []
      if (type === 'auto') {
        this.innerVisibleAutoLeading = true
      } else {
        this.innerVisible = true
        this.fetchExamineData()
      }
    },
    getPACSTypes() {
      this.axios.get('/v1/webconsole/examination/list/examine_types').then(({ data: res }) => {
        if (res && res.data && res.data.length > 0) {
          let arr = []
          res.data.map((item) => {
            arr.push({
              label: item.examineTypeName,
              value: item.examineTypeCode
            })
          })
          this.PACSOptions = arr
        }
      })
    },
    handlePACSTemplet() {
      this.$refs['upload'].$refs['upload-inner'].handleClick()
      this.pacsTypeInnerVisible = false
    },
    handleUpPACS() {
      this.PACSTypeCode = ''
      this.pacsTypeInnerVisible = true
    },
    handlePACS() {
      var date
      if (this.currentPatient.planStudyDate) {
        date = new Date(this.currentPatient.planStudyDate)
      } else {
        date = new Date()
      }
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const todayDate = `${year}-${month}-${day}`

      var timestamp = date.getTime() // 获取时间戳

      // 减去 7 天的毫秒数
      var subtractedTimestamp = timestamp - 7 * 24 * 60 * 60 * 1000

      var subtractedDate = new Date(subtractedTimestamp) // 创建新的 Date 对象
      var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      var formattedDate = subtractedDate.toLocaleDateString('zh-CN', options).replace(/\//g, '-')
      this.dialogQueryDate = [
        this.currentPatient?.admissionDate || formattedDate,
        this.currentPatient.studyDate || todayDate
      ]
      this.dialogQueryList = this.dialogQueryDate.join(',')
      this.PACSExamineData = []
      this.syncFirstStatus = true

      this.PACSinnerVisible = true
    },
    fetchPACSExamineData() {
      let examineTypeCode = this.tableList[Number(this.activeName)].examineTypeCode
      let formData = {
        startDate: this.dialogQueryDate[0],
        stopDate: this.dialogQueryDate[1],
        examineTypeCode: examineTypeCode,
        syncFirst: this.syncFirstStatus ? true : false
      }
      this.$api
        .get(`/v1/webconsole/examination/patient/pacs/${this.info.patientsIndex}`, formData)
        .then(({ data: res }) => {
          if (res && res.data) {
            this.PACSExamineData = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    savePACSOrAdviceList(val) {
      if (this.currentPatient.patientsID) {
        this.axios
          .post(
            `/v1/webconsole/examination/import/examine/${val.uuid}?formId=${
              this.tableList[this.activeName].formId
            }`
          )
          .then(({ data: res }) => {
            if (res && res.data) {
              this.isResearch = false
              this.PACSinnerVisible = false
              let newArr = JSON.parse(JSON.stringify(this.tabDataList[Number(this.activeName)].tab))
              newArr.map((item) => {
                res.data.newForm.map((items) => {
                  if (item.id === items.id) {
                    if (items.value) {
                      item.value = items.value
                    }
                  }
                })
              })
              this.tabDataList[Number(this.activeName)].tab = newArr
            }
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.examineId = res.data.examine.uuid
            this.tabDataList[Number(this.activeName)].examinationInfo.push(res.data.examine)
          })
      }
    },
    // 日期更改
    handleDialogQueryDate() {
      if (!this.dialogQueryDate) {
        this.dialogQueryList = null
      } else {
        this.dialogQueryList = this.dialogQueryDate.join(',')
      }
    },
    getParentValue(val, arr) {
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i]
        if (val.parentId === element.id) {
          if (val.parentValue) {
            element.value = val.parentValue
          }
        }
      }
    },
    async allSaveOrAdviceList() {
      let url = `/v1/webconsole/examination/import/lis/${this.info.patientsIndex}`
      let formData = new FormData()
      formData.append('formId', this.tableList[this.activeName].formId)
      formData.append('menuCode', this.menuListData.code)
      formData.append('startDate', this.dialogQueryDate[0])
      formData.append('stopDate', this.dialogQueryDate[1])

      await this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data && res.data.elements) {
          const elements = res.data.elements
          res.data.examinationIds.forEach((item) => {
            if (!this.handleItemUuid.includes(item)) {
              this.handleItemUuid.push(item)
            }
          })
          let newArr = JSON.parse(JSON.stringify(this.tabDataList[Number(this.activeName)].tab))
          newArr.map((item) => {
            item.elementId = item.id
            elements?.map((ele) => {
              if (item.id === ele.id) {
                item.value = ele.value
              }
            })
          })
          this.tabDataList[Number(this.activeName)].isSave = false
          this.tabDataList[Number(this.activeName)].newTab = JSON.parse(
            JSON.stringify(this.tabDataList[Number(this.activeName)].tab)
          )
          setTimeout(() => {
            this.tabDataList[Number(this.activeName)].tab = newArr
          }, 200)
        }
      })
      this.isResearch = false
      this.isReadonly = false
      this.innerVisible = false
    },
    async saveOrAdviceList() {
      this.handleItemList = getLatestExamineData(this.queryExamOrAdviceList)
      let newArr = JSON.parse(JSON.stringify(this.tabDataList[Number(this.activeName)].tab))
      newArr.map((item) => {
        item.elementId = item.id
        this.handleItemList?.map((items) => {
          if (item.elementId === items.elementId) {
            if (items.itemValue) {
              item.value = items.itemValue
            }
            if (item.parentId !== '-1') {
              this.getParentValue(item, newArr)
            }
          }
        })
      })
      this.tabDataList[Number(this.activeName)].isSave = false
      this.tabDataList[Number(this.activeName)].newTab = JSON.parse(
        JSON.stringify(this.tabDataList[Number(this.activeName)].tab)
      )
      setTimeout(() => {
        this.tabDataList[Number(this.activeName)].tab = newArr
      }, 200)
      if (this.handleItemUuid.length > 0) {
        const newArrId = []
        this.handleItemUuid.forEach((item) => {
          if (!newArrId.includes(item)) {
            newArrId.push(item)
          }
        })
        if (!this.tabDataList[this.activeName].id) {
          let arr = JSON.parse(JSON.stringify(this.tabDataList[Number(this.activeName)].tab))
          let dataList = {
            items: arr,
            examineIds: newArrId
          }
          dataList.items.forEach((item) => {
            item.elementId = item.id
            if (item.type === 'checkbox') {
              if (item.value?.length > 0) {
                item.value = item.value?.join(',')
              } else {
                item.value = null
              }
            }
          })
          let url = `/v1/webconsole/eform/operation/save/${this.info.uuid}/${this.menuListData.opStage}`
          let data = {
            formId: '',
            id: '',
            ...dataList
          }
          data.formId = this.tableList[Number(this.activeName)].formId
          await this.$api.post(url, data).then((res) => {
            this.tabDataList[Number(this.activeName)].id = res.data.data.id
            if (res.data && res.data.data) {
              this.$api
                .get(`/v1/webconsole/examination/eform/examination/${res.data.data.id}`)
                .then((res) => {
                  if (res.data && res.data.data) {
                    this.tabDataList[Number(this.activeName)].examinationInfo = res.data.data
                  } else {
                    this.tabDataList[Number(this.activeName)].examinationInfo = []
                  }
                })
            }
          })
        }
      }
      this.isResearch = false
      this.isReadonly = false
      this.innerVisible = false
    },
    // 查询导入数据
    fetchExamineData() {
      let arr = this.tableList[Number(this.activeName)]
      if (this.dialogQueryList) {
        this.$api
          .get(`/v1/webconsole/examination/operation/lis/${this.info.patientsIndex}`, {
            startDate: this.dialogQueryDate[0],
            stopDate: this.dialogQueryDate[1],
            formId: arr.formId,
            menuCode: this.menuListData.code,
            businessId: this.info.uuid,
            syncFirst: this.syncFirstStatus ? true : false
          })
          .then((res) => {
            if (res.data && res.data.data) {
              // res.data.data.sort(
              //   (b, a) => new Date(a.examineDate).getTime() - new Date(b.examineDate).getTime()
              // ) //时间升序
              this.queryExamOrAdviceList = res.data.data
              this.queryExamOrAdviceList.forEach((item) =>
                item.items.forEach((ite) => (ite._isSecect = false))
              )
              let newArr = []
              res.data.data.map((item) => {
                item.items.map((ele) => {
                  newArr.push(ele) // 取出所有的子项
                })
              })
              // 处理数据 保持同一长度
              this.columnHeaderList = this.uniqueJsonArrByField(newArr, 'itemTitle') || [] // 去重
              res.data.data.map((item) => {
                let newColumnHeaderList = JSON.parse(JSON.stringify(this.columnHeaderList))
                const newList = newColumnHeaderList.map((ele) => {
                  ele.itemValue = null
                  ele.uuid = parseInt(Math.random(0, 1) * 1000000)
                  item.items.map((thirdItem) => {
                    if (ele.itemTitle === thirdItem.itemTitle) {
                      ele = { ...thirdItem, uuid: ele.uuid }
                    }
                  })
                  return ele
                })
                item.items = newList
              })
              setTimeout(() => {
                this.columnPreviewList = JSON.parse(JSON.stringify(this.columnHeaderList))
                this.queryExamOrAdviceList.forEach((ele, index) => {
                  this.initColumns(index)
                })
              }, 200)
              this.isResearch = false
            }
          })
      } else {
        this.$message.error('日期不能为空')
      }
    },
    // handleItem(column, columnIndex, cell) {
    //   cell._isSecect = !cell._isSecect
    //   this.queryExamOrAdviceList.forEach((columns, i) => {
    //     if (columnIndex !== i) {
    //       columns.items.forEach((ele) => {
    //         if (cell.itemTitle === ele.itemTitle) {
    //           ele._isSecect = false
    //           if (
    //             ele.itemValue &&
    //             new Date(columns.examineDate) - new Date(column.examineDate) > 0 &&
    //             ele._isSecect
    //           ) {
    //             cell._isSecect = !cell._isSecect
    //           } else if (ele.itemValue && !cell.itemValue && cell._isSecect) {
    //             cell._isSecect = false
    //             ele._isSecect = true
    //           } else {
    //             ele._isSecect = false
    //           }
    //         }
    //       })
    //     }
    //   })

    //   this.handleItemUuid = column.items
    //     .filter((cell) => cell._isSecect)
    //     .map((cell) => cell.examinationId)

    //   this.$forceUpdate()
    // },

    handleItem(column, columnIndex, cell) {
      if (cell.itemValue) {
        cell._isSecect = !cell._isSecect
      }
      this.handleItemUuid = []

      this.queryExamOrAdviceList.forEach((columns, i) => {
        if (columnIndex !== i) {
          columns.items.forEach((ele) => {
            if (cell.itemTitle === ele.itemTitle && cell.itemValue) {
              ele._isSecect = false

              if (ele.itemValue) {
                if (
                  new Date(columns.examineDate) - new Date(column.examineDate) > 0 &&
                  ele._isSecect
                ) {
                  cell._isSecect = !cell._isSecect
                } else if (!cell.itemValue && cell._isSecect) {
                  cell._isSecect = false
                  ele._isSecect = true
                }
              }
            }
          })
        }
        this.handleItemUuid.push(
          ...columns.items.filter((cell) => cell._isSecect).map((cell) => cell.examinationId)
        )
      })

      this.columnPreviewList.forEach((item, index) => {
        if (item.itemTitle === cell.itemTitle && cell.itemValue) {
          const clonedItem = { ...cell }
          if (!cell._isSecect) {
            clonedItem.itemValue = null
          }
          this.$set(this.columnPreviewList, index, clonedItem)
        }
      })

      this.$forceUpdate()
    },

    selectColumns(i) {
      this.queryExamOrAdviceList.forEach((cln, cellIndex) => {
        cln.items.forEach((cell) => {
          if (i === cellIndex) {
            if (cell.itemValue) {
              this.handleItem(cln, i, cell)
            } else {
              cell._isSecect = false
            }
          }
        })
      })
      this.$forceUpdate()
    },

    initColumns(index) {
      const column = this.queryExamOrAdviceList[index]

      column.items.forEach((cell) => {
        if (cell.itemValue) {
          let shouldSelect = true
          this.queryExamOrAdviceList.forEach((otherColumn, otherIndex) => {
            if (index !== otherIndex) {
              otherColumn.items.forEach((otherCell) => {
                if (cell.itemTitle === otherCell.itemTitle && otherCell._isSecect) {
                  shouldSelect = false
                }
              })
            }
          })

          if (shouldSelect) {
            cell._isSecect = true
            this.columnPreviewList.forEach((item, index) => {
              if (item.itemTitle === cell.itemTitle) {
                item.itemValue = cell.itemValue
              }
            })
          }
        }
      })
      this.handleItemUuid.push(
        ...column.items.filter((cell) => cell._isSecect).map((cell) => cell.examinationId)
      )
      this.$forceUpdate()
    },
    handleClick() {
      this.handleItemUuid = []
      this.examineId = null
      this.isResearch = true
      if (this.tabDataList[this.activeName].id) {
        this.getExamination()
      } else {
        this.tabDataList[Number(this.activeName)].examinationInfo = []
      }
      if (this.tabDataList[this.lastIndex].isSave === false) {
        this.tabDataList[Number(this.lastIndex)].tab = JSON.parse(
          JSON.stringify(this.tabDataList[Number(this.lastIndex)].newTab)
        )
      }
      eval('this.$refs.tab' + this.lastIndex)[0].isEdit()
      this.lastIndex = this.activeName
    },
    changeBasicInfo() {
      this.isResearch = !this.isResearch
      if (this.isResearch) {
        eval('this.$refs.tab' + this.activeName)[0].isEdit()
      }
      if (this.tabDataList[this.activeName].id) {
        this.getExamination()
      } else {
        this.tabDataList[Number(this.activeName)].examinationInfo = []
      }
      this.handleItemUuid = []
    },
    saveEFormInfo(val) {
      val.items.forEach((item) => {
        item.elementId = item.id
      })
      let url = `/v1/webconsole/eform/operation/save/${this.info.uuid}/${this.menuListData.opStage}`
      let data = {
        formId: '',
        id: '',
        ...val
      }
      if (this.examineId) {
        data.examineIds.push(this.examineId)
      }
      if (this.tabDataList[Number(this.activeName)]?.id) {
        data.formId = this.tabDataList[Number(this.activeName)].formId
        data.id = this.tabDataList[Number(this.activeName)].id
      } else {
        data.formId = this.tableList[Number(this.activeName)].formId
      }

      this.$api.post(url, data).then(
        (res) => {
          this.isResearch = true
          this.disabled = false
          this.type = 'primary'
          this.$message.success(res.data.msg)
          this.tabDataList[this.activeName].isSave === true
          if (Number(this.activeName) + 1 < this.tableList.length) {
            this.activeName = (Number(this.activeName) + 1).toString()
            this.changeBasicInfo()
          }
          this.getTable()
          this.scrollToTop()
          this.$bus.$emit('saveFormInfo')
        },
        () => {
          this.loading = false
        }
      )
    },
    saveBasicInfo() {
      this.disabled = true
      this.loading = true
      this.type = 'info'
      eval('this.$refs.tab' + this.activeName)[0].Save()
    },
    isLoading() {
      this.type = 'primary'
      this.disabled = false
      this.loading = false
    },
    // 获取table列表
    getTable() {
      if (Object.keys(this.info).length) {
        let url = `/v1/webconsole/eform/operation/form/${this.info.uuid}/${this.menuListData.opStage}`
        this.$api.get(url).then(({ data: res }) => {
          if (res && res.data) {
            this.tabDataList = []
            this.tableList = res.data

            const promise = res.data.map(async (item) => {
              let url = `/v1/webconsole/eform/operation/formdata/${this.info.uuid}/${this.menuListData.opStage}`
              let data = {
                formId: item.formId
              }
              const res = await this.$api.get(url, data)
              return res.data.data
            })
            Promise.all(promise)
              .then((result) => {
                result.map((item) => {
                  if (item.length > 0) {
                    this.tabDataList.push({
                      ...item[0],
                      examinationInfo: [],
                      tab: []
                    })
                  } else {
                    this.tabDataList.push({
                      examinationInfo: [],
                      tab: []
                    })
                  }
                })
              })
              .then(() => {
                this.getTableData()
                this.loading = false
              })
          }
        })
      }
    },
    getTableData() {
      for (let i = 0; i < this.tabDataList.length; i++) {
        const element = this.tabDataList[i]
        if (element.id) {
          let url = `/v1/webconsole/eform/elements/${element.formId}/${element.id}`
          this.$api.get(url).then((res) => {
            if (res.data && res.data.data) {
              this.$set(element, 'tab', res.data.data)
            }
          })
        } else {
          let url = '/v1/webconsole/eform/newform'
          let data = {
            formId: this.tableList[i].formId
          }
          this.$api.get(url, data).then((res) => {
            if (res.data && res.data.data) {
              this.$set(element, 'tab', res.data.data)
            }
          })
        }
      }
      if (this.tabDataList[this.activeName].id) {
        this.getExamination()
      }
    }
  }
}
</script>
<style lang="less">
.el-tooltip__popper {
  max-width: 700px;
}
</style>
<style lang="less" scoped>
.main-content {
  background: #eff0f7;
}
.container {
  .el-divider__text.is-left {
    font-size: 18px;
  }
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .body {
    flex: 1;
    overflow: auto;
    margin-top: 10px;
    margin-left: 10px;
    border-top-left-radius: 8px;
    /deep/.el-card__body {
      padding: 0 0 0 10px;
    }
  }
  .crt-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .crt-content-left {
      height: 28px;
      line-height: 28px;
      width: 55%;
    }
    .crt-content-right {
      display: flex;
      margin-right: 20px;
      height: 28px;
      line-height: 28px;
    }
  }
  /deep/.el-tabs {
    margin-top: 5px;
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__active-bar {
      background: #2d507e;
      border: #2d507e;
    }
    .el-tabs__item.is-active {
      color: #2d507e;
    }
    .el-tabs__item:hover {
      color: #427bc0;
    }
    // .el-tabs__content {
    //   width: 55%;
    // }
    .el-tabs__item {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
    .el-descriptions-item__container {
      margin: 5px 10px;
      .my-label {
        min-width: 120px;
        line-height: 32px;
      }
    }
  }
}
.add-pacs {
  /deep/.el-dialog {
    // width: 1000px !important;
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }
  }
}
.add-form {
  /deep/.el-dialog {
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }

    .el-dialog__body {
      height: 600px;
      overflow-y: auto;
      padding: 0;

      .dialog-btn-wrap {
        position: absolute;
        height: 50px;
        width: 100%;
        background-color: #f2f2f2;
        z-index: 100;
        display: flex;
        align-items: center;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }
    .form-wrap {
      margin-top: 80px;
      padding: 0 20px;
      height: 500px;
      overflow-y: auto;

      .adviceTable {
        display: flex;
        margin: 15px 0;
        .adviceTableLabel {
          white-space: nowrap;
          background-color: #aae1fe;
          .adviceTableHeader {
            margin: 1px -1px -1px 1px;
            background-color: #00a5ff;
            // border: 1px solid #4c6fe2;
            border-top: 1px solid #4c6fe2;
            border-bottom: 1px solid #4c6fe2;
            border-left: 1px solid #4c6fe2;
            border-right: 1px solid #4c6fe2;
            height: 30px;
          }
          .adviceTableLabelItem {
            margin: 1px -1px -1px 1px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            // border: 1px solid #4c6fe2;
            border-bottom: 1px solid #4c6fe2;
            border-right: 1px solid #4c6fe2;
            border-left: 1px solid #4c6fe2;
          }
        }
        .adviceTablePreviewLabel {
          text-align: center;
          min-width: 120px;
          .adviceTableHeader {
            margin: 1px -1px -1px 1px;
            background-color: #00a5ff;
            border-top: 1px solid #4c6fe2;
            border-bottom: 1px solid #4c6fe2;
            border-right: 1px solid #4c6fe2;
            height: 30px;
            line-height: 30px;
            font-weight: bold;
            color: #fff;
          }
          .adviceTableLabelItem {
            margin: 1px -1px -1px 1px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-bottom: 1px solid #4c6fe2;
            border-right: 1px solid #4c6fe2;
          }
        }
        .adviceTableBox {
          display: flex;
          .adviceTableContent {
            min-width: 140px;
            flex-shrink: 0;
            .adviceTableHeader {
              padding: 0 10px;
              margin: 1px -1px -1px 1px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              background-color: #00a5ff;
              // border: 1px solid #4c6fe2;
              border-top: 1px solid #4c6fe2;
              border-bottom: 1px solid #4c6fe2;
              border-right: 1px solid #4c6fe2;
            }
            .queryExamToday {
              background-color: #5aff009c !important;
            }
            .adviceTableContentItem {
              margin: 1px -1px -1px 1px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              // border: 1px solid #00a5ff;
              border-bottom: 1px solid #4c6fe2;
              border-right: 1px solid #4c6fe2;
              cursor: pointer;
            }
          }

          .cell-pitch_on {
            background-color: #aae1fe;
          }
          .adviceTableContentItem:hover {
            margin: 1px -1px -1px 1px;
            // border: 1px solid #000;
            background: #d2eeff;
          }
        }
      }
      .el-divider__text {
        background-color: #f8f8f8;
      }
      .el-row {
        margin-bottom: 15px;
      }
      .el-input {
        width: 250px;
      }
      .el-input__inner {
        border-color: #dcdfe6;
        height: 35px;
      }
      .next {
        margin-bottom: 15px;
      }
      .el-tabs__header {
        position: fixed;
        z-index: 100;
        background: #fff;
      }
      .fixed-btn {
        margin-top: 40px;
        .el-button {
          position: fixed;
          z-index: 500;
        }
      }
      .el-tabs__content {
        .container {
          margin-top: 80px;
        }
      }
    }
  }
}
.auto-leading {
  /deep/.el-dialog {
    width: 30% !important;
    .el-dialog__body {
      height: 200px;
    }
  }
}
</style>
